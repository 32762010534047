import { createTheme, PaletteMode } from "@mui/material";
import NestleTextBoldOtf from "./assets/fonts/NestleText-Bold.otf";
import NestleTextBoldItalicOtf from "./assets/fonts/NestleText-BoldItalic.otf";
import NestleTextBookOtf from "./assets/fonts/NestleText-Book.otf";
import NestleTextItalicOtf from "./assets/fonts/NestleText-Italic.otf";
import NestleTextLightOtf from "./assets/fonts/NestleText-Light.otf";
import NestleTextLightItalicOtf from "./assets/fonts/NestleText-LightItalic.otf";
import NestleTextBoldWoff2 from "./assets/fonts/NestleText-Bold.woff2";
import NestleTextBoldItalicWoff2 from "./assets/fonts/NestleText-BoldItalic.woff2";
import NestleTextBookWoff2 from "./assets/fonts/NestleText-Book.woff2";
import NestleTextItalicWoff2 from "./assets/fonts/NestleText-Italic.woff2";
import NestleTextLightWoff2 from "./assets/fonts/NestleText-Light.woff2";
import NestleTextLightItalicWoff2 from "./assets/fonts/NestleText-LightItalic.woff2";

export const applicationTheme = {
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: bold;
          src: url("${NestleTextBoldOtf}") format('otf'),url("${NestleTextBoldWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: bold;
          src: url("${NestleTextBoldItalicOtf}") format('otf'),url("${NestleTextBoldItalicWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: 400;
          src: url("${NestleTextBookOtf}") format('otf'),url("${NestleTextBookWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: 400;
          src: url("${NestleTextItalicOtf}") format('otf'),url("${NestleTextItalicWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: 100;
          src: url("${NestleTextLightOtf}") format('otf'),url("${NestleTextLightWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: 100;
          src: url("${NestleTextLightItalicOtf}") format('otf'),url("${NestleTextLightItalicWoff2}") format('woff2');
        }
      `,
    },
  },
  typography: {
    fontFamily: "NestleText, Roboto, sans-serif !important",
  },
  palette: {
    mode: "light" as PaletteMode,
    text: {
      primary: "#63513d", //"#5f5f5f",
    },
    primary: {
      contrastText: "#FFF",
      main: "#F19001",
    },
    secondary: {
      main: "#2689EB",
    },
    background: {
      default: "#F2F4F8",
    },
    error: {
      main: "#f35454"
    },
    success: {
      main: "#8ab863",
    },
  },
};

export const nspTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: bold;
          src: url("${NestleTextBoldOtf}") format('otf'),url("${NestleTextBoldWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: bold;
          src: url("${NestleTextBoldItalicOtf}") format('otf'),url("${NestleTextBoldItalicWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: 400;
          src: url("${NestleTextBookOtf}") format('otf'),url("${NestleTextBookWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: 400;
          src: url("${NestleTextItalicOtf}") format('otf'),url("${NestleTextItalicWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: normal;
          font-weight: 100;
          src: url("${NestleTextLightOtf}") format('otf'),url("${NestleTextLightWoff2}") format('woff2');
        }
        @font-face {
          font-family: 'NestleText';
          font-style: italic;
          font-weight: 100;
          src: url("${NestleTextLightItalicOtf}") format('otf'),url("${NestleTextLightItalicWoff2}") format('woff2');
        }
      `,
    },
  },
  typography: {
    fontFamily: "NestleText, Roboto, sans-serif !important",
  },
  palette: {
    mode: "light" as PaletteMode,
    primary: {
      main: "#067bca", //'#fff',
    },
    secondary: {
      // light: '#f7f6fb',
      main: "#52b485", //'#f1f4f5',
      contrastText: "#fff",
    },
    error: {
      main: "#d85353",
    },
    warning: {
      main: "#e28f4a",
    },
    success: {
      main: "#52b485",
    },
    action: {
      active: "#63513d", //'#666e72',
    },
    text: {
      primary: "#63513d", //'#666e72',
    },
    background: {
      default: "#f7f6fb",
    },
  },
};

export const muiTheme = createTheme(applicationTheme);
export const nspMuiTheme = createTheme(nspTheme);
