import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormHelperText,
  Grid,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { CampaignMaterialType } from "../service/campaigns.types";
import { useQuery } from "@tanstack/react-query";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { useJustification } from "modules/UI/hooks/useJustification.hook";
import { UserService } from "modules/User/service/user.service";
import { FileRejection, DropEvent } from "react-dropzone";
import { MediaFileSchema } from "../service/campaigns.schemas";
import CampaignsService from "../service/campaigns.service";
import * as yup from "yup";
import { Remove, Add } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useActiveUser } from "modules/User/activeUser";
import Dropzone from "modules/Forms/components/dropzone.component";

export const CampaignMaterialPreOrderButton = (props) => {
  const { material, campaign, requests, onRefetch } = props;
  const [quantity, setQuantity] = useState(material.minimumPurchaseQuantity);
  const currentUser = useActiveUser();
  const { data: userFullScope } = useQuery({
    queryKey: ["userFullScope"],
    queryFn: async () => {
      const result = await UserService.fetchUserFullScope();
      return result;
    },
    initialData: [],
  });
  const requestQuote = useJustification({
    title: `Your order`,
    description: "Your comments",
    submitButtonText: `Submit`,
    additionalData: [
      {
        key: "quantity",
        initialValue: quantity,
        validationSchema: yup
          .number()
          .required()
          .min(
            material.minimumPurchaseQuantity,
            "Minimum quantity is " + material.minimumPurchaseQuantity
          )
          .test(
            "multiples-of-mpq",
            "Quantity values must be multiples of Minimum Pack Quantity (MPQ)",
            function (value, context) {
              // const quantities = context.from[1].value.priceRanges.map(
              //   (range) => range.threshold
              // );
              return value % material.minimumPurchaseQuantity === 0;
            }
          ),
      },
      {
        key: "mediaFiles",
        initialValue: [],
        validationSchema: yup.array().of(MediaFileSchema).notRequired(),
      },
      {
        key: "market",
        initialValue: null,
        validationSchema: yup
          .object()
          .shape({
            id: yup.string().required(),
            name: yup.string().required(),
          })
          .required("Market is required"),
      },
    ],
    additionalContent: ({ values, setFieldValue }) => (
      <div>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mt: 2 }}
        >
          <Grid item xs={12}>
            <TextFieldWrapper name="quantity" label="Quantity" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              placeholder={
                "You can optionaly attach a file by dropping it here"
              }
              fileName={values.mediaFiles[0]?.originalFileName}
              onRemoveFile={
                values.mediaFiles.length === 0
                  ? null
                  : (fileName) => {
                      CampaignsService.deleteQuantityRequestMedia({
                        fileId: values.mediaFiles[0]?.fileId,
                      }).then(() => {
                        setFieldValue("mediaFiles", []);
                      });
                    }
              }
              onDrop={async function <T extends File>(
                acceptedFiles: T[],
                fileRejections: FileRejection[],
                event: DropEvent
              ): Promise<void> {
                CampaignsService.uploadQuantityRequestMedia({
                  file: acceptedFiles[0],
                }).then((result) => {
                  setFieldValue("mediaFiles", [result]);
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            Location
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              size="small"
              value={values.market?.id}
              onChange={(e) => {
                setFieldValue("market", {
                  id: e.target.value,
                  name: e.target.value,
                });
              }}
            >
              {userFullScope
                .filter((zoneScope) => {
                  return zoneScope.markets.some((marketScope) => {
                    return !!campaign.markets.find(
                      (m) => m.id === marketScope.market.id
                    );
                  });
                })
                .map((zoneScope) => {
                  return [
                    <ListSubheader>---{zoneScope.zone.name}---</ListSubheader>,
                    zoneScope.markets
                      .filter((marketScope) => {
                        return !!campaign.markets.find(
                          (m) => m.id === marketScope.market.id
                        );
                      })
                      .map((marketScope) => (
                        <MenuItem
                          key={marketScope.market.id}
                          value={marketScope.market.id}
                          disabled={
                            requests?.requests
                              .map((req) => req.market.id)
                              .indexOf(marketScope.market.id) > -1
                          }
                        >
                          {marketScope.market.name}{" "}
                          {requests?.requests
                            .map((req) => req.market.id)
                            .indexOf(marketScope.market.id) > -1 ? (
                            <> - already requested</>
                          ) : (
                            <></>
                          )}
                        </MenuItem>
                      )),
                  ];
                })}
              <MenuItem />
            </Select>
          </Grid>
        </Grid>
      </div>
    ),
  });
  return (
    <>
      {requestQuote.JSX}
      <Box textAlign={"center"}>
        <ButtonGroup variant="outlined" color="primary">
          <Tooltip arrow title="Decrease quantity">
            <Button
              disabled={quantity <= material.minimumPurchaseQuantity}
              onClick={() => {
                setQuantity((prev) => prev - material.minimumPurchaseQuantity);
              }}
            >
              <Remove />
            </Button>
          </Tooltip>
          <Tooltip arrow title={material.canOrderQuantities ? "Pre-Order" : "You are not configured to Order Quantity for this Market."}>
            <Button
              size="large"
              variant="contained"
              disabled={material.canOrderQuantities !== true}
              onClick={() => {
                requestQuote
                  .requestJustification()
                  .then((data) => {
                    CampaignsService.requestMaterialQuantity({
                      ...data,
                      requestor: currentUser.email,
                      comments: data.justification,
                      quantity: data.quantity,
                      orderWindowMaterialId: material.id,
                    })
                      .then((result) => {
                        toast.success("Pre-order was successful.");
                        if (onRefetch) onRefetch();
                      })
                      .catch((e) => {
                        toast.success("Something went wrong.");
                        if (onRefetch) onRefetch();
                      });
                    setQuantity(material.minimumPurchaseQuantity);
                  })
                  .catch(() => {
                    setQuantity(material.minimumPurchaseQuantity);
                    if (onRefetch) onRefetch();
                  });
              }}
            >
              Pre-Order {quantity} {material.unitOfMeasure}
            </Button>
          </Tooltip>
          <Tooltip arrow title="Increase quantity">
            <Button
              onClick={() => {
                setQuantity((prev) => prev + material.minimumPurchaseQuantity);
              }}
            >
              <Add />
            </Button>
          </Tooltip>
        </ButtonGroup>
        {/* <FormHelperText sx={{ textAlign: "center" }}>
          est. cost{" "}
          {quantity *
            [...material.priceRanges]
              .sort((a, b) => {
                return a.threshold < b.threshold ? 1 : -1;
              })
              .find((r) => quantity >= r.threshold)?.unitPrice}{" "}
          {material.currencyCode}
        </FormHelperText> */}
      </Box>
    </>
  );
};

export default CampaignMaterialPreOrderButton;
