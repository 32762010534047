import React, { useEffect, useState } from "react";
import { CampaignShortDTO, CampaignState } from "../service/campaigns.types";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Chip,
  SxProps,
  Tooltip,
} from "@mui/material";
import {
  NotificationAddOutlined,
  NotificationsOffOutlined,
  Share,
  Visibility,
} from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { createBlobService } from "utils/factories";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import CampaignsService from "../service/campaigns.service";
import { useActiveUser } from "modules/User/activeUser";

export type CampaignCardProps = {
  id: string | number;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  created: string;
  modified: string;
  imageUrl: string;
  isUserSubscribed?: boolean;
  onRefetch?: () => void;
  state: CampaignState;
  sx?: SxProps;
};

export const CampaignCard = (props: CampaignCardProps) => {
  const currentUser = useActiveUser();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const {
    mutateAsync: unsubscribeMemberFromCampaign,
    isPending: unsubscribePending,
  } = useMutation({
    mutationKey: ["unsubscribe", props.id],
    mutationFn: (email: string) =>
      CampaignsService.unsubscribeMemberFromCampaign({
        campaignId: props.id,
        email,
      }),
    onSuccess: (data, variables, context) => {
      toast.success("Unsubscribed successfully");
      props.onRefetch?.();
    },
  });
  const { mutate: subscribeMeToCampaign, isPending: subscribeMePending } =
    useMutation({
      mutationKey: ["subscribeMe", props.id],
      mutationFn: () =>
        CampaignsService.subcribeMeToCampaign({ campaignId: props.id }),
      onSuccess: (data, variables, context) => {
        toast.success("Subscribed successfully");
        props.onRefetch?.();
      },
    });

  useEffect(() => {
    createBlobService(props.imageUrl, "GET")().then((blob) => {
      setImageUrl(URL.createObjectURL(blob));
    });
  }, [props.imageUrl]);

  return (
    <Card
      onClick={() => navigate(`/campaigns/${props.id}`)}
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: 320,
        flex: "1 1 320px",
        // mt: 3,
        // mb: 3,
        transition: ".2s ease-out",
        position: "relative",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.1);",
          boxShadow: (theme) => theme.shadows[8],
          zIndex: 20,
        },
        ...(props.sx || {}),
      }}
    >
      <CardHeader
        sx={{
          flexFlow: "row wrap",
          justifyContent: "flex-start",
          gap: 1,
          "& .MuiCardHeader-content": {
            width: "100%",
            flex: "0 0 100%",
            "& .MuiCardHeader-title": {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          },
        }}
        title={props.name}
        action={
          <Chip
            label={
              props.state === CampaignState.Draft
                ? `Modified ${moment(props.modified).fromNow()}`
                : props.state === CampaignState.Active
                ? `Ending ${moment(props.endDate).fromNow()}`
                : `Starting ${moment(props.startDate).fromNow()}`
            }
          />
        }
      />
      <CardMedia
        component="img"
        height={(320 * 9) / 16}
        image={imageUrl}
        sx={{ pointerEvents: "none", mt: "auto" }}
      />
      <CardActions>
        <ButtonGroup sx={{ ml: "auto" }}>
          {!!navigator.share && (
            <Tooltip title="Share" arrow>
              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  try {
                    await navigator.share({
                      title: props.name,
                      text: props.description,
                      url: "https://example.com",
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Share />
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Toggle notifications" arrow>
            <Button
              disabled={unsubscribePending || subscribeMePending}
              onClick={(e) => {
                e.stopPropagation();
                props.isUserSubscribed
                  ? unsubscribeMemberFromCampaign(currentUser.email)
                  : subscribeMeToCampaign();
              }}
            >
              {props.isUserSubscribed ? (
                <NotificationsOffOutlined />
              ) : (
                <NotificationAddOutlined />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="View details" arrow>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/campaigns/${props.id}`);
              }}
            >
              Details
            </Button>
          </Tooltip>
        </ButtonGroup>
      </CardActions>
    </Card>
  );
};

export default CampaignCard;
