import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import {
  CustomFastAsyncSelect,
  CustomFastSelect,
} from "modules/Forms/components/form.legacy.component";
import { SUPPLIER_NOT_DEFINED_CODE } from "modules/PromoItems/containers/createStepOne.container";
import PromoItemsService from "modules/PromoItems/services/promoItems.service";
import { UserFullScopeComponent } from "modules/User/components/userScopeSelector/userFullScopeComponent";
import { values } from "ramda";
import React, { useCallback } from "react";
import CampaignsService from "../service/campaigns.service";
import {
  CampaignMaterialSchema,
  CreateCampaignSchema,
} from "../service/campaigns.schemas";
import { CreateCampaignDTO } from "../service/campaigns.types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { InferType } from "yup";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";
import { DatePickerWrapper } from "modules/Forms/components/datePickerWrapper.component";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectSupplierContact from "modules/Forms/components/selectSupplierContact.component";
import { MediaManager } from "modules/PromoItems/components/MediaManager.component";
import moment from "moment";
import { UserService } from "modules/User/service/user.service";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";
import { useActiveUser } from "modules/User/activeUser";
import { HelpOutline } from "@mui/icons-material";

type CreateCampaignContainerProps = {
  onClose?: () => void;
  initialData?: CreateCampaignDTO;
};

export const CreateCampaignContainer = (
  props: CreateCampaignContainerProps
) => {
  const { onClose } = props;
  const navigate = useNavigate();
  const features = useFeatures();
  const currentUser = useActiveUser();
  const initialValues: InferType<typeof CreateCampaignSchema> = {
    mediaFiles: [],
    startDate: moment().toISOString(),
    endDate: moment().add(3, "M").toISOString(),
    validTo: moment().add(5, "M").toISOString(),
    ...(props.initialData || {}),
  };
  const { data: userFullScope, isLoading: loadingFullUserScope } = useQuery({
    queryKey: ["userFullScope"],
    queryFn: () => UserService.fetchUserFullScope(),
  });

  const { mutateAsync: createCampaign, isPending: creatingCampaign } =
    useMutation({
      mutationKey: ["createCampaign", values],
      mutationFn: CampaignsService.createCampaign,
      onSuccess: (data) => {
        if (onClose) {
          onClose();
        }
        toast.success(`Campaign ${data.id} created successfully`, {
          onClick: () => {
            navigate(`/campaigns/${data.id}`);
          },
        });
      },
    });

  const { mutateAsync: updateCampaign, isPending: updatingCampaign } =
    useMutation({
      mutationKey: ["updateCampaign", values],
      mutationFn: CampaignsService.updateCampaign,
      onSuccess: (data) => {
        if (onClose) {
          onClose();
        }
        toast.success(`Campaign ${data.id} updated successfully`, {
          onClick: () => {
            navigate(`/campaigns/${data.id}`);
          },
        });
      },
    });
  const { mutateAsync: uploadCampaignMedia } = useMutation<any, any, any>({
    mutationKey: ["uploadCampaignMedia"],
    mutationFn: async (data) => {
      const response = await CampaignsService.uploadCampaignMedia(data);
      return response;
    },
  });

  // const { mutateAsync: uploadCampaignMediaFromLink } = useMutation<
  //   any,
  //   any,
  //   any
  // >({
  //   mutationKey: ["uploadCampaignMediaFromLink"],
  //   mutationFn: async ({ link }) => {
  //     const response = await CampaignsService.uploadCampaignMediaFromLink({
  //       link,
  //     });
  //     return response;
  //   },
  // });

  const { mutateAsync: deleteCampaignMedia } = useMutation<any, any, any>({
    mutationKey: ["deleteCampaignMedia"],
    mutationFn: async (fileId) => {
      await CampaignsService.deleteCampaignMedia({ fileId });
      return fileId;
    },
  });
  return (
    <Formik
      // enableReinitialize  // dont enable this, it will cause the form to reset on every render and break the creation of the campaign
      validationSchema={CreateCampaignSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (values.id) {
          updateCampaign(values);
        } else {
          createCampaign(values);
        }
      }}
    >
      {({
        handleSubmit,
        isValid,
        errors,
        getFieldMeta,
        setValues,
        values,
        setFieldValue,
      }) => {
        return (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            {/* <pre>
            <code>{JSON.stringify(errors, null, 3)}</code>
          </pre> */}
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              sx={{ height: "100%" }}
            >
              <Grid item sx={{ flex: "1 0 420px" }}>
                <Paper sx={{ p: 2, minHeight: "100%" }}>
                  <Typography sx={{ mb: 1 }} variant="h5">
                    Supplier
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Parent Vendor:</FormLabel>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{ position: "relative", zIndex: 2 }}
                    >Parent Pending Approval
                      <CustomFastAsyncSelect
                        onLoad={(searchTerm) => {
                          return PromoItemsService.getPslAmdrSuppliersHierarchy(
                            {
                              searchTerm,
                            }
                          ).then((results) => {
                            results.push({
                              id: SUPPLIER_NOT_DEFINED_CODE,
                              name: "Supplier Not Defined",
                            });
                            return results;
                          });
                        }}
                        onLoadLimit={3}
                        hasError={!!getFieldMeta("supplierCode").error}
                        customDisplay={(option) =>
                          `${option.id} - ${option.name}`
                        }
                        initialValue={
                          values.supplierCode
                            ? {
                                id: values.supplierCode,
                                name: values.supplierName,
                              }
                            : null
                        }
                        onChange={(value) => {
                          PromoItemsService.fetchChildrenSuppliersV2({
                            locationCompanyCodes: null,
                            childrenOf: value.id,
                            parentId: value.id,
                            zones: null,
                          }).then((response) => {
                            console.log({ response });
                          });
                          setValues({
                            ...values,
                            supplierCode: value?.id,
                            supplierName: value?.name,
                          });
                        }}
                      />
                      {!!getFieldMeta("supplierCode").error && (
                        <FormHelperText error>
                          {getFieldMeta("supplierCode").error}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Contact:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <SelectSupplierContact
                        supplierId={values.supplierCode}
                        value={values.supplierContact?.email}
                        onChange={(contact) => {
                          setFieldValue("supplierContact.email", contact.email);
                          setFieldValue(
                            "supplierContact.username",
                            `${contact.firstname} ${contact.lastname}`
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item sx={{ flex: "1 0 420px" }}>
                <Paper sx={{ p: 2, minHeight: "100%" }}>
                  <Typography sx={{ mb: 1 }} variant="h5">
                    Campaign
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Name:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextFieldWrapper name="name" size="small" fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Description:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextFieldWrapper
                        multiline
                        rows={4}
                        size="small"
                        fullWidth
                        name="description"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Start Date:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <DatePickerWrapper
                        name="startDate"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>End Date:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <DatePickerWrapper
                        name="endDate"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        // onChange={(value) => {
                        //   if (value) {
                        //     setFieldValue("endDate", value);
                        //   }
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Prices are valid until:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <DatePickerWrapper
                        name="validTo"
                        slotProps={{
                          textField: {
                            size: "small",
                            fullWidth: true,
                          },
                        }}
                        // onChange={(value) => {
                        //   if (value) {
                        //     setFieldValue("endDate", value);
                        //   }
                        // }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Incoterms:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Box display="flex" gap={2} alignItems={"flex-start"}>
                        <FormControl
                          fullWidth
                          size="small"
                          error={!!getFieldMeta("incoTerms").error}
                        >
                          <InputLabel id="incotermslabel">
                            {`Incoterms
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "incoTerms"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                          </InputLabel>
                          <Select
                            labelId="incotermslabel"
                            id="incoterms-select"
                            label={`Incoterms
                        ${
                          !(
                            CampaignMaterialSchema.describe().fields[
                              "incoTerms"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                            // fullWidth
                            // size="small"
                            value={values.incoTerms}
                            onChange={(e) =>
                              setFieldValue("incoTerms", e.target.value)
                            }
                          >
                            <MenuItem value={"FOB"}>FOB</MenuItem>
                            <MenuItem value={"DDP"}>DDP</MenuItem>
                          </Select>
                          {!!getFieldMeta("incoTerms").error && (
                            <FormHelperText error>
                              {getFieldMeta("incoTerms").error}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Tooltip
                          arrow
                          title={
                            "Indicate the Incoterms of the price you negotiated"
                          }
                        >
                          <HelpOutline sx={{ mt: 0.8, cursor: "help" }} />
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Agreements:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextFieldWrapper
                        name="agreements"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormLabel>Location:</FormLabel>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <SupplierScope />
                    </Grid>
                    <Grid item xs={12}>
                      <MediaManager
                        error={getFieldMeta("mediaFiles").error}
                        images={
                          values.mediaFiles?.filter(
                            (file) => file.mimeType.indexOf("image/") > -1
                          ) || []
                        }
                        // onUploadFromUrl={(link) => {
                        //   uploadCampaignMediaFromLink({
                        //     link,
                        //     campaignId: values.id,
                        //   })
                        //     .then((response) => {
                        //       setFieldValue(
                        //         "mediaFiles",
                        //         values.mediaFiles.concat(response)
                        //       );
                        //     })
                        //     .catch((err) => {
                        //       console.error(err);
                        //     });
                        // }}
                        onUpload={(files) => {
                          uploadCampaignMedia({
                            file: files[0],
                            campaignId: values.id,
                          })
                            .then((response) => {
                              setFieldValue(
                                "mediaFiles",
                                values.mediaFiles.concat(response)
                              );
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        }}
                        onDelete={(mediaId) => {
                          deleteCampaignMedia(mediaId).then((deletedFileId) => {
                            setFieldValue(
                              "mediaFiles",
                              values.mediaFiles.filter(
                                (f) => f.fileId !== deletedFileId
                              )
                            );
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {["ivan.ivanov@nestle.com"].indexOf(
                currentUser.email.toLowerCase()
              ) > -1 && (
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary>Values</AccordionSummary>
                    <AccordionDetails>
                      <pre>
                        <code>{JSON.stringify(values, null, 3)}</code>
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary>Errors</AccordionSummary>
                    <AccordionDetails>
                      <pre>
                        <code>{JSON.stringify(errors, null, 3)}</code>
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"flex-end"}
                sx={{ mt: "auto", pb: 2 }}
              >
                <Button
                  type="reset"
                  color="primary"
                  variant="outlined"
                  size="large"
                  sx={{ ml: "auto" }}
                  onClick={() => onClose()}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="large"
                  sx={{ ml: 2 }}
                  disabled={!isValid || creatingCampaign || updatingCampaign}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const SupplierScope = () => {
  const { values, setValues, setFieldValue } = useFormikContext<any>();
  const { data, isLoading } = useQuery({
    queryKey: ["supplierScope", values.supplierCode],
    enabled: !!values.supplierCode,
    queryFn: () =>
      PromoItemsService.getAmdrSuppliersHierarchyWithScope([
        values.supplierCode,
      ]),
    initialData: [],
  });

  return isLoading ? (
    <>loading</>
  ) : (
    <CustomFastSelect
      isMulti={true}
      disabled={isLoading}
      initialValue={values.markets?.map((v) => ({
        value: v.id,
        label: v.name,
      }))}
      options={data
        ?.reduce((acc, zone) => {
          return [...acc, ...zone.markets.map((market) => market.market)];
        }, [])
        ?.map((v) => ({
          value: v.id,
          label: v.name,
        }))}
      onChange={(value) => {
        setFieldValue(
          "markets",
          value?.map((v) => ({ id: v.value, name: v.label })) || []
        );
      }}
    />
  );
};

export default CreateCampaignContainer;
