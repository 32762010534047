import {
  CloudUploadOutlined,
  Delete,
  Remove,
  Star,
  StarOutline,
  Visibility,
} from "@mui/icons-material";
import {
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  TextField,
} from "@mui/material";
import Dropzone from "modules/Forms/components/dropzone.component";
import {
  AuthenticatedImage,
  AuthenticatedImageBackgroundDiv,
} from "modules/UI/components/AuthenticatedImage.component";
import Drawer from "modules/UI/components/Drawer.component";
import { props } from "ramda";
import React, { useState } from "react";
import { FileRejection, DropEvent } from "react-dropzone";
import { createBlobService } from "utils/factories";

function fileToDataUrl(file: File) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

export type MediaManagerPropsType = {
  onUpload?: (files: File[]) => void;
  onDelete?: (mediaId: string) => void;
  onUploadFromUrl?: (link: string) => void;
  images?: any[];
  error?: any;
  readOnly?: boolean;
};

export type MediaManagerDrawerPropsType = MediaManagerPropsType & {
  open?: boolean;
  onClose?: () => void;
};

export const MediaManager = ({
  onUpload,
  onUploadFromUrl,
  onDelete,
  images,
  error,
  readOnly,
}: MediaManagerPropsType) => {
  const [url, setUrl] = useState<string>("");
  return (
    <>
      {!readOnly && (
        <>
          {!!onUploadFromUrl && (
            <TextField
              type="url"
              placeholder="https://example.com/image.jpg"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              label="Upload from URL"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onUploadFromUrl(url);
                  setUrl("");
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={
                      !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
                        url
                      )
                    }
                    onClick={() => {
                      onUploadFromUrl(url);
                      setUrl("");
                    }}
                  >
                    <CloudUploadOutlined />
                  </IconButton>
                ),
              }}
            />
          )}
          <Dropzone
            error={error}
            multiple
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
            }}
            placeholder={"Drag and drop more media files here..."}
            onDrop={async function <T extends File>(
              acceptedFiles: T[],
              fileRejections: FileRejection[],
              event: DropEvent
            ): Promise<void> {
              !!onUpload && onUpload(acceptedFiles);
            }}
          />
        </>
      )}
      <ImageList cols={3} rowHeight={164}>
        {images?.map((item) => (
          <ImageListItem key={item.fileId} sx={{ overflow: "hidden" }}>
            <AuthenticatedImageBackgroundDiv
              src={item.url}
              // alt={item.originalFileName}
              // loading="lazy"
              style={{
                width: 142,
                height: 165,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                border: "1px solid #eee",
              }}
            />
            {/* <img
              srcSet={`${item.url}`}
              src={`${item.url}`}
              alt={item.originalFileName}
              loading="lazy"
            /> */}
            <ImageListItemBar
              actionIcon={
                <>
                  {/* <Tooltip title="Feature">
                <IconButton sx={{ color: "rgba(255, 255, 255, 0.54)" }}>
                  <StarOutline />
                </IconButton>
              </Tooltip> */}
                  <Tooltip title="Preview">
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => {
                        createBlobService(item.url, "GET")().then((blob) => {
                          window.open(URL.createObjectURL(blob), "_blank");
                        });
                      }}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {!readOnly && (
                    <Tooltip title="Remove">
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                        onClick={() => {
                          !!onDelete && onDelete(item.fileId);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export const MediaManagerDrawer = ({
  open,
  onClose,
  onUpload,
  onUploadFromUrl,
  onDelete,
  images,
  readOnly,
}: MediaManagerDrawerPropsType) => {
  return (
    <Drawer open={open} onClose={() => onClose()} title="Manage Mutimedia">
      <MediaManager
        onUpload={onUpload}
        onUploadFromUrl={onUploadFromUrl}
        onDelete={onDelete}
        images={images}
        readOnly={readOnly}
      />
    </Drawer>
  );
};

export default MediaManagerDrawer;
