import {
  createQueryService,
  createFormDataService,
  createMutationService,
} from "utils/factories";

export default class EasyRequestService {
  static readonly getEasyRequestCounter = createQueryService<
    { username: string; count: string | number },
    never
  >("/api/easyRequest/Counter");
  static readonly addPslMaterialById = createMutationService<
    any,
    { materialId: string, quantity: number }
  >(({ materialId, quantity }) => `/api/easyRequest/AddPslMaterialById/${materialId}?quantity=${quantity}`);
}
