import {
  DeleteOutline,
  EditOutlined,
  FileCopyOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { CampaignState } from "../service/campaigns.types";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";

export const CampaignMaterialCard = (props) => {
  const { material, campaign, requests, onRefetch } = props;
  return (
    <>
      <Card sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          {material.mediaFiles.length > 0 ? (
            <PUIContentCarousel
              data={material.mediaFiles.map((file) => ({
                type: "image",
                thumbnail: file.url,
                src: file.url,
              }))}
              // onPreview={() => {}}
            />
          ) : (
            <PUIRatioBox xRatio={16} yRatio={9}>
              <></>
              {/* <Avatar
              variant="square"
              sx={{
                width: "100%",
                height: "100%",
                // background: "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,multiply",
                backgroundColor: promoCategory?.color || "#ccc",
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,.1))",
                backgroundBlendMode: "overlay",
                "& svg": {
                  opacity: 0.2,
                  width: "50%",
                  height: "50%",
                },
              }}
            >
              {promoCategory ? <promoCategory.icon /> : <></>}
            </Avatar> */}
            </PUIRatioBox>
          )}
        </Box>
        <CardContent>{material.shortDescription}</CardContent>
        <CardActionArea>
          {campaign.state === CampaignState.Draft && (
            <Box textAlign={"center"} sx={{ mb: 2 }}>
              <ButtonGroup variant="outlined" color="primary">
                <Tooltip arrow title="Delete material">
                  <Button onClick={() => props.onRemove?.(material)}>
                    <DeleteOutline />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Duplicate material">
                  <Button onClick={() => props.onDuplicate?.(material)}>
                    <FileCopyOutlined />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Edit material">
                  <Button onClick={() => props.onEdit?.(material)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
          )}
          {campaign.state !== CampaignState.Draft && (
            <Button
              size="large"
              variant="text"
              fullWidth
              onClick={() => {
                props.onDetails?.(material);
              }}
            >
              Details & Pre-Order
            </Button>
          )}
        </CardActionArea>
      </Card>
    </>
  );
};
